import { Line } from "react-chartjs-2"
import "chartjs-adapter-date-fns"
import { useAuth0 } from "@auth0/auth0-react"
import { MessageType, useMessageReport } from "../MessageReporter"
import { useEffect, useMemo, useRef, useState } from "react"
import { ApiClient } from "../../ApiClient"
import { differenceInMilliseconds, milliseconds } from "date-fns"
import { Spinner } from "react-bootstrap"
import classNames from "classnames"
import { VentilationLevels } from "../../enums/VentilationLevels"
import { HistoryContextProvider, useHistory } from "../HistoryContext"
import { DeviceHistoryControls } from "./DeviceHistoryControls"

const colors = [
    "#F44336", // Red
    "#8BC34A", // Light Green
    "#03A9F4", // Light Blue
    "#FF9800", // Orange
    "#2196F3", // Blue
    "#4CAF50", // Green
    "#9C27B0", // Purple
    "#00BCD4", // Cyan
    "#3F51B5", // Indigo
]
const nowLineColor = "#A5D6A7"

const defaultDisplay = [
    "room_setpoint",
    "ambient_temperature",
    "flame_state",
    "connected",
    "central_heating_enabled",
    "backup_heater_1_active",
    "backup_heater_2_active",
    "ventilation_level",
]

const airFilters = [
    "air_outdoor_temperature",
    "air_exhaust_temperature",
    "air_return_speed",
    "air_flow_setpoint",
    "air_ducts_position",
]
const ambientFilters = ["ambient_eco2", "ambient_humidity", "ambient_temperature", "room_setpoint"]
const waterFilters = [
    "water_return_temperature",
    "water_supply_temperature",
    "central_heating_water_pressure",
    "water_condenser_water_out_temperature",
    "water_pump_flow",
]
const heatPumpFilter = [
    "heat_pump_hot_gas_temperature",
    "heat_pump_condenser_refrigerant_out_temperature",
    "heat_pump_evaporator_refrigerant_out_temperature",
    "heat_pump_evaporation_pressure",
    "heat_pump_condensation_pressure",
    "heat_pump_air_exhaust_temperature",
    "heat_pump_current_compressor_speed",
]

const statusFiltersEnum = ["ventilation_level"]
const statusFiltersBoolean = [
    "flame_state",
    "connected",
    "central_heating_enabled",
    "backup_heater_1_active",
    "backup_heater_2_active",
]
const statusFilters = statusFiltersEnum.concat(statusFiltersBoolean)

const settings = [
    {
        duration: { hours: 3 },
        format: "HH:mm",
        unit: "minute",
        resolution: "minute",
        maxTicks: 20,
    },
    {
        duration: { days: 1, minutes: -1 },
        format: "HH:mm",
        unit: "hour",
        resolution: "minute",
        maxTicks: 12,
    },
    {
        duration: { days: 2 },
        format: "dd/MM",
        unit: "day",
        resolution: "hour",
        maxTicks: 100,
    },
    {
        duration: { months: 1 },
        format: "dd/MM",
        unit: "day",
        resolution: "day",
        maxTicks: 16,
    },
    {
        duration: { years: 1, days: -3 },
        format: "MM",
        unit: "month",
        resolution: "day",
        maxTicks: 100,
    },
]

const globalFilters = [
    "air_outdoor_temperature",
    "air_exhaust_temperature",
    "air_return_speed",
    "air_flow_setpoint",
    "air_ducts_position",
    "ambient_eco2",
    "ambient_humidity",
    "ambient_temperature",
    "water_return_temperature",
    "water_supply_temperature",
    "central_heating_water_pressure",
    "water_condenser_water_out_temperature",
    "water_pump_flow",
    "heat_pump_hot_gas_temperature",
    "heat_pump_condenser_refrigerant_out_temperature",
    "heat_pump_evaporator_refrigerant_out_temperature",
    "heat_pump_evaporation_pressure",
    "heat_pump_condensation_pressure",
    "heat_pump_air_exhaust_temperature",
    "heat_pump_current_compressor_speed",

    "flame_state",
    "room_setpoint",
    "ventilation_level",
    "connected",
    "central_heating_enabled",
    "backup_heater_1_active",
    "backup_heater_2_active",
]

const continuousLines = globalFilters.slice(0, 19)
const eventLines = globalFilters.filter((x) => !continuousLines.includes(x))

export const SystemGraphs = ({ systemId }) => (
    <HistoryContextProvider>
        <SystemGraphsInternal systemId={systemId} />
    </HistoryContextProvider>
)

const SystemGraphsInternal = ({ systemId }) => {
    const { getAccessTokenSilently } = useAuth0()
    const { addMessage } = useMessageReport()
    const { addGraphData, start, end, afterZoom } = useHistory()

    const chartRefs = useRef([])

    const [loading, setLoading] = useState(false)

    const [data, setData] = useState({ datasets: [] })
    const setting = useMemo(() => settingFromPeriod(start, end), [start, end])

    // Needed because the Date objects in start and end change often,
    // without their content actually changing.
    const startStr = start.toISOString()
    const endStr = end.toISOString()
    useEffect(() => {
        if (!chartRefs?.current) {
            return
        }

        chartRefs.current.forEach((chartRef, i) => {
            const chart = chartRef

            if (chart == null) {
                return
            }

            const items = chart._metasets.filter((set) => set.visible)

            const data = items.flatMap((item) =>
                item._dataset.data.map((datapoint) => ({
                    timestamp: datapoint.x.toISOString(),
                    datapointType: item.label,
                    value: datapoint.y,
                }))
            )

            addGraphData(i, data)
        })
    }, [chartRefs, data, addGraphData])

    useEffect(() => {
        setLoading(true)
        getAccessTokenSilently()
            .then(async (token) => {
                const urls = []

                urls.push(
                    `/v2/consumer/history/system` +
                        `/${systemId}` +
                        `/${startStr}` +
                        `/${endStr}` +
                        `/original` +
                        `?filter=${eventLines.join(",")}`
                )

                urls.push(
                    `/v2/consumer/history/system` +
                        `/${systemId}` +
                        `/${startStr}` +
                        `/${endStr}` +
                        `/${setting.resolution}` +
                        `?filter=${continuousLines.join(",")}`
                )

                const results = await Promise.all(
                    urls.map((url) =>
                        ApiClient.get(url, {
                            Authorization: "Bearer " + token,
                        })
                    )
                )

                const data = results
                    .flatMap((res) => res.data)
                    .flatMap((data) => data.history)
                    .filter((items) => items && Object.keys(items).length > 0)

                return Object.assign({}, ...data)
            })
            .then((data) => setData(mapHistory(data)))
            .catch((err) =>
                addMessage("Device history", "Could not get device history", MessageType.error, err)
            )
            .finally(() => setLoading(false))
    }, [startStr, endStr, setting, addMessage, systemId, getAccessTokenSilently])

    return Object.entries(data.datasets).map((set, i) => (
        <div key={set[1].title}>
            {i % 2 === 0 && <DeviceHistoryControls />}
            <div className={classNames("history-graph", { loading: loading })}>
                <h4>{set[1].title}</h4>
                <GraphHelper
                    data={{
                        datasets: set[1].datasets,
                    }}
                    afterZoom={afterZoom}
                    start={start}
                    end={end}
                    setting={setting}
                    chartRef={(element) => chartRefs.current.push(element)}
                    isStatus={set[1].title === "Status"}
                />
                <div>
                    <Spinner variant="primary" />
                </div>
            </div>
        </div>
    ))
}

const GraphHelper = ({ data, afterZoom, start, end, setting, chartRef, isStatus }) => {
    const options = {
        responsive: true,
        elements: {
            point: {
                radius: 1,
            },
        },
        scales: {
            x: {
                type: "time",
                min: start.valueOf(),
                max: end.valueOf(),
                time: {
                    unit: setting.unit,
                    tooltipFormat: "dd-MM-yyyy HH:mm:ss",
                    displayFormats: {
                        minute: setting.format,
                        hour: setting.format,
                        day: setting.format,
                        week: setting.format,
                        month: setting.format,
                        year: setting.format,
                    },
                },
                ticks: {
                    maxTicksLimit: setting.maxTicks,
                    align: "start",
                },
            },
            y: {
                suggestedMin: 0,
            },
            yInts: {
                display: false,
            },
        },
        plugins: {
            legend: {
                position: "bottom",
                labels: {
                    usePointStyle: true,
                },
            },
            zoom: {
                limits: {
                    x: {
                        min: "original",
                        max: "original",
                        minRange: milliseconds({ minutes: 3 }),
                    },
                },
                zoom: {
                    drag: {
                        enabled: true,
                    },
                    mode: "x",
                    onZoomComplete: ({ chart }) =>
                        afterZoom(chart.scales.x.min, chart.scales.x.max),
                },
            },
            annotation: {
                annotations: [
                    {
                        type: "line",
                        id: "vline1",
                        mode: "vertical",
                        scaleID: "x",
                        value: new Date(),
                        borderColor: nowLineColor,
                        borderWidth: 2,
                        label: {
                            display: true,
                            position: "start",
                            content: "Now",
                            backgroundColor: nowLineColor,
                        },
                    },
                ],
            },
        },
    }

    if (isStatus) {
        options.scales.y = {
            suggestedMin: 0,
            suggestedMax: statusFiltersBoolean.length * 2 - 1,
            stack: "customVertical",
            // Uncomment if it is necessary to shrink this axis relative to the other
            // stackWeight: 0.25,
            offset: true,
            ticks: {
                precision: 0,
                callback: (value) => (value % 2 !== 0 ? "ON" : "OFF"),
                font: {
                    size: 10,
                },
            },
        }
        options.scales.yInts = {
            display: true,
            suggestedMin: 0,
            suggestedMax: 4,
            stack: "customVertical",
            ticks: {
                precision: 0,
            },
        }
        options.plugins.tooltip = {
            callbacks: {
                label: (value) =>
                    statusFiltersEnum.includes(value.dataset.key)
                        ? value.formattedValue
                        : value.raw.y % 2 !== 0
                        ? "ON"
                        : "OFF",
            },
        }

        data.datasets.forEach((dataset, i) => {
            if (statusFiltersEnum.includes(dataset.key)) {
                dataset.yAxisID = "yInts"
            } else {
                dataset.yAxisID = "y"
                dataset.data = dataset.data.map((d) => ({
                    ...d,
                    y: (d.y * 1) % 2 === 0 ? i * 2 : i * 2 + 1,
                }))
            }
        })
    }

    return <Line options={options} ref={chartRef} data={data} />
}

const MappedLevels = VentilationLevels.map((x) => x.toUpperCase())

const mapHistory = (res) => {
    let categorySets = {
        ambient: {
            datasets: [],
            title: "Ambient",
        },
        air: {
            datasets: [],
            title: "Air",
        },
        water: {
            datasets: [],
            title: "Water",
        },
        heatPump: {
            datasets: [],
            title: "Heat pump",
        },
        status: {
            datasets: [],
            title: "Status",
        },
    }

    Object.entries(res).forEach((entry) => {
        if (globalFilters.includes(entry[0]) && entry[1].data.length > 0) {
            let values = []

            if (entry[0] === "ventilation_level") {
                values = entry[1].data.map((x) => {
                    const value = x.value
                    const level = MappedLevels.indexOf(value) + 1

                    return {
                        x: new Date(x.timestamp),
                        y: level,
                    }
                })
            } else {
                values = entry[1].data.map((x) => ({
                    x: new Date(x.timestamp),
                    y: x.value,
                }))
            }

            let set = []

            if (airFilters.includes(entry[0])) {
                set = categorySets.air.datasets
            } else if (ambientFilters.includes(entry[0])) {
                set = categorySets.ambient.datasets
            } else if (statusFilters.includes(entry[0])) {
                set = categorySets.status.datasets
            } else if (waterFilters.includes(entry[0])) {
                set = categorySets.water.datasets
            } else if (heatPumpFilter.includes(entry[0])) {
                set = categorySets.heatPump.datasets
            } else {
                return
            }

            const stepped = continuousLines.indexOf(entry[0]) < 0 ? "after" : false
            const shown = defaultDisplay.includes(entry[0])
            set.push({
                label: filterToLabel(entry),
                key: entry[0],
                data: values,
                backgroundColor: colors[set.length],
                borderColor: colors[set.length],
                borderWidth: 1,
                cubicInterpolationMode: "monotone",
                stepped: stepped,
                hidden: !shown,
                order: !shown,
            })
        }
    })

    return {
        datasets: categorySets,
    }
}

const filterToLabel = ([key, value]) =>
    key[0].toUpperCase() +
    key.slice(1).replaceAll("_", " ") +
    (value.unit === "none" ? "" : " (" + value.unit + ")")

const settingFromPeriod = (start, end) => {
    const range = differenceInMilliseconds(end, start)

    let setting = settings[0]
    for (let i = 0; i < settings.length; i++) {
        const settingRange = milliseconds(settings[i].duration)
        if (settingRange <= range) {
            setting = settings[i]
        }
    }
    return setting
}
